export default {
	fetchContent: function (url, callback) {
		var request = new XMLHttpRequest();
		request.open('GET', url, true);
		request.send();
		request.onload = function () {
			if (this.status >= 200 && this.status < 400) {
				var resp = this.response;
				callback(resp);
			}
			else {
				callback('');
			}
		};
	},
	isBot: function () {
		const botList = /bot|googlebot|crawler|spider|robot|crawling/i;
		return botList.test(navigator.userAgent);
	},
	isToTrack: function () {
		var dnt = navigator.doNotTrack || navigator.msDoNotTrack || window.doNotTrack;
		return (dnt !== null && dnt !== undefined) ? (dnt && dnt !== 'yes' && dnt !== 1 && dnt !== '1') : true;
	},
	cookieAge: function () {
		var expiryDate = new Date().setFullYear(new Date().getFullYear() + 1);
		return new Date(expiryDate);
	},
};
