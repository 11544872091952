import cookieHandler from './libs/cookies.js';
import utils from './helpers/utils.js';
import dataFeed from './presets/pmi.js';
import lang from './lang/pmi.l10n.js';

const GTMDATA = {
	cookieSettings: '{"event":"GAEvent","eventCategory":"Button","eventAction":"Click","eventLabel":"cookies-find-out-more"}',
	cookieAcceptAll: '{"event":"GAEvent","eventCategory":"Button","eventAction":"Click","eventLabel":"cookies-accept"}',
};

const defaults = {
	lang: 'cs',
	theme: 'pmi-default',
	dataFeed: 'pmi',
	pathSrc: 'https://cookies.pmdata.cz/',
};

class CookieBar {
	constructor (options) {
		this.options = {
			...defaults,
			...options,
		};

		// settings - datafeedy templaty atd
		this.options = {
			...dataFeed[this.options.dataFeed],
			...lang[this.options.dataFeed][this.options.lang],
			...this.options,
		};

		this._buildNotice();
		this._buildSettingsWindow();

		this._setElements();
		this._setCommands();
	}

	_setCommands () {
		var cookieList = this.options.data.cookieTypes;
		for (var i = 0; i < cookieList.length; i++) {
			var el = cookieList[i];
			var cookieName = el.cookieName;
			var cookieValue = cookieHandler.getItem(cookieName);
			if (cookieValue === '1' || el.waitAccept === false || el.voluntary === false) {
				this._execCookie(cookieName);
			}
		}
	}

	_setElements () {
		var hasCookieConsentObsolete = cookieHandler.getItem('hasConsent');
		if (hasCookieConsentObsolete === 'true') {
			var cookieExpires = utils.cookieAge();
			cookieHandler.setItem('cookieConsent', '1', cookieExpires, '/');
			cookieHandler.setItem('cookieConsentAge', new Date().toUTCString(), '/');
			cookieHandler.setItem('cookieFunkcni', '1', cookieExpires, '/');
			cookieHandler.removeItem('hasConsent');
			console.log('cookies enabled from before');
		}


		var hasCookieConsent = cookieHandler.getItem('cookieConsent');

		if (hasCookieConsent !== '1' && utils.isToTrack() && !utils.isBot()) {
			document.querySelector('.cookie-notice').classList.add('is-opened');
		}

		var _this = this;
		document.querySelector('.cookie-menu-link').classList.add('active');
		document.querySelector('.cookie-content').classList.add('active');

		var checkboxes = document.querySelectorAll('.cookie-checkbox');
		for (var i = 0; i < checkboxes.length; i++) {
			var el = checkboxes[i];
			var cookieName = el.getAttribute('data-cookie-ref');
			var curCookieValue = cookieHandler.getItem(cookieName);
			var thisCookieSettings = _this.options.data.cookieTypes.filter(function (obj) {
				return obj.cookieName === cookieName;
			})[0];

			if (curCookieValue === '0') {
				el.checked = false;
			}
			else if (curCookieValue === '1') {
				el.checked = true;
			}
			else {
				el.checked = thisCookieSettings.checkedByDefault;
			}
		}

		var settingsLinks = document.querySelectorAll('[data-cookie-link=\'cookieSettings\']');
		for (var i = 0; i < settingsLinks.length; i++) {
			var el = settingsLinks[i];
			el.addEventListener('click', function (e) {
				e.preventDefault();
				var target = document.querySelector('.cookie-settings'); // _this.getAttribute("data-cookie-link");
				var cookieNotice = document.querySelector('.cookie-notice');
				target.classList.add('is-opened');
				cookieNotice.classList.remove('is-opened');
			});
		}

		document.querySelector('.cookie-btn-save').addEventListener('click', function () {
			var target = document.querySelector('.cookie-settings');
			target.classList.remove('is-opened');
			var checkboxes = document.querySelectorAll('.cookie-checkbox');
			var cookieExpires = utils.cookieAge();
			for (var i = 0; i < checkboxes.length; i++) {
				var el = checkboxes[i];
				var cookieValue = el.checked ? '1' : '0';
				var cookieName = el.getAttribute('data-cookie-ref');
				cookieHandler.setItem(cookieName, cookieValue, cookieExpires, '/');
				if (cookieValue === '1') {
					this._execCookie(cookieName);
				}
			}

			cookieHandler.setItem('cookieConsent', '1', cookieExpires, '/');
			cookieHandler.setItem('cookieConsentAge', new Date().toUTCString(), '/');
			document.querySelector('.cookie-notice').classList.remove('is-opened');
		}.bind(this));

		document.querySelector('.cookie-btn-accept-all').addEventListener('click', function () {
			var cookieList = this.options.data.cookieTypes;
			for (var i = 0; i < cookieList.length; i++) {
				var el = cookieList[i];
				var cookieName = el.cookieName;
				var cookieValue = '1';
				var cookieExpires = utils.cookieAge();
				if (el.voluntary === true) {
					this._execCookie(cookieName);
					cookieHandler.setItem(cookieName, cookieValue, cookieExpires, '/');
					document.getElementById('accept-' + cookieName).checked = true;
				}
			}

			cookieHandler.setItem('cookieConsent', '1', cookieExpires, '/');
			cookieHandler.setItem('cookieConsentAge', new Date().toUTCString(), '/');
			document.querySelector('.cookie-notice').classList.remove('is-opened');
		}.bind(this));
	}

	_execCookie (cookieName) {
		var cookieSettings = this.options.cookieSettings;
		if (typeof cookieSettings === 'undefined') {
			return;
		}

		var cookieCode = cookieSettings[cookieName];
		if (typeof cookieCode === 'undefined' || cookieCode._running) {
			return;
		}
		var thisCookieSettings = this.options.cookieSettings[cookieName];
		if (typeof thisCookieSettings.runCommand === 'function') {
			thisCookieSettings.runCommand();
			thisCookieSettings._running = true;
		}
	}

	_buildNotice () {
		var _this = this;
		var noticeContainer = document.createElement('div');
		noticeContainer.className = 'cookie-notice';
		noticeContainer.classList.add('cookie-notice--' + _this.options.theme);
		var noticeDescription = document.createElement('div');
		noticeDescription.className = 'cookie-notice__description';

		var noticeDescriptionInner = document.createElement('div');
		noticeDescriptionInner.className = 'cookie-notice__description-inner';
		noticeDescriptionInner.insertAdjacentHTML('beforeend', _this.options.texts.cookieNotice.description);

		noticeDescription.appendChild(noticeDescriptionInner);
		noticeContainer.appendChild(noticeDescription);

		var noticeButtons = document.createElement('div');
		noticeButtons.className = 'cookie-notice__buttons';

		var noticeButtonsInner = document.createElement('div');
		noticeButtonsInner.className = 'cookie-notice__buttons-inner';

		var btnShowSettings = document.createElement('button');
		btnShowSettings.className = 'cookie-btn-show-settings';
		btnShowSettings.dataset.gtmPush = GTMDATA.cookieSettings;
		btnShowSettings.dataset.cookieLink = 'cookieSettings';
		btnShowSettings.textContent = _this.options.texts.buttons.moreInfo;

		var btnAcceptAll = document.createElement('button');
		btnAcceptAll.className = 'cookie-btn-accept-all';
		btnAcceptAll.dataset.gtmPush = GTMDATA.cookieAcceptAll;
		btnAcceptAll.dataset.cookieAcceptAll = '';
		btnAcceptAll.textContent = _this.options.texts.buttons.acceptAll;

		noticeButtonsInner.appendChild(btnShowSettings);
		noticeButtonsInner.appendChild(btnAcceptAll);
		noticeButtons.appendChild(noticeButtonsInner);

		noticeContainer.appendChild(noticeButtons);

		document.querySelector('body').appendChild(noticeContainer);
		console.log('cookies bar in DOM');
	}

	_buildFormCheck (id, label, cookieRef) {
		var labelEl = document.createElement('label');
		labelEl.classList.add('cookie-checkbox-label');

		var inputEl = document.createElement('input');
		inputEl.type = 'checkbox';
		inputEl.classList.add('cookie-checkbox');
		inputEl.id = id;
		inputEl.setAttribute('data-cookie-ref', cookieRef);
		var indicatorEl = document.createElement('span');
		indicatorEl.classList.add('cookie-checkbox-indicator');

		var labelText = document.createTextNode(label);

		labelEl.appendChild(inputEl);
		labelEl.appendChild(indicatorEl);
		labelEl.appendChild(labelText);

		labelEl.addText;
		return labelEl;
	}

	_buildContentItem (settings, texts) {
		var _this = this;
		var cookieContent = document.createElement('div');
		cookieContent.setAttribute('class', 'cookie-content');
		cookieContent.setAttribute('id', 'cookieContent-' + settings.id);
		var cookieContentInner = document.createElement('div');
		cookieContentInner.classList.add('cookie-content-inner');

		var cookieHeading = document.createElement('h2');
		cookieHeading.setAttribute('class', 'cookie-content-heading');
		cookieHeading.textContent = texts.heading.trim();
		cookieContentInner.appendChild(cookieHeading);
		cookieContentInner.insertAdjacentHTML('beforeend', texts.description.trim());



		cookieContentInner.appendChild(_this._buildCookieTable(settings.cookieName));
		cookieContent.appendChild(cookieContentInner);

		if (settings.voluntary) {
			cookieContent.appendChild(_this._buildFormCheck('accept-' + settings.cookieName, _this.options.texts.buttons.accept, settings.cookieName));
		}


		return cookieContent;
	}

	_buildMenuItem (settings, texts) {
		var cookieMenuItem = document.createElement('a');
		cookieMenuItem.setAttribute('href', '#cookieContent-' + settings.id);
		cookieMenuItem.setAttribute('class', 'cookie-menu-link');
		cookieMenuItem.setAttribute('id', 'cookieLink-' + settings.id);
		cookieMenuItem.innerHTML = texts.menuLabel.trim();

		cookieMenuItem.addEventListener('click', function (e) {
			var _this = this;
			e.preventDefault();
			var target = _this.href.substring(_this.href.lastIndexOf('#') + 1);
			var cookieContents = document.querySelectorAll('.cookie-content');
			for (var i = 0; i < cookieContents.length; i++) {
				var el = cookieContents[i];
				el.classList.remove('active');
			}
			var menuContents = document.querySelectorAll('.cookie-menu-link');
			for (var i = 0; i < menuContents.length; i++) {
				var el = menuContents[i];
				el.classList.remove('active');
			}
			_this.classList.add('active');

			document.getElementById(target).classList.add('active');
			document.getElementById(target).querySelector('.cookie-content-inner').scrollTop = 0;
		});
		return cookieMenuItem;
	}

	_buildContent () {
		var _this = this;
		var cookiesTexts = _this.options.texts.cookieTypes;
		var cookiesSettings = _this.options.data.cookieTypes;
		var cookieContent = document.createElement('div');
		cookieContent.className = 'cookie-content-container';
		var cookieContentWrap = document.createElement('div');
		cookieContentWrap.className = 'cookie-content-wrap';
		cookieContent.appendChild(cookieContentWrap);
		for (var i = 0; i < cookiesSettings.length; i++) {
			var curSettings = cookiesSettings[i];
			var curTexts = cookiesTexts.filter(function (obj) {
				return obj.id === curSettings.id;
			})[0];

			var cookieContentItem = _this._buildContentItem(curSettings, curTexts);

			cookieContentWrap.appendChild(cookieContentItem);
		}

		document.querySelector('.cookie-settings').appendChild(cookieContent);
	}

	_buildMenu () {
		var _this = this;
		var cookiesTexts = _this.options.texts.cookieTypes;
		var cookiesSettings = _this.options.data.cookieTypes;
		var cookieMenu = document.createElement('div');
		cookieMenu.setAttribute('class', 'cookie-menu');

		for (var i = 0; i < cookiesSettings.length; i++) {
			var curSettings = cookiesSettings[i];
			var curTexts = cookiesTexts.filter(function (obj) {
				return obj.id === curSettings.id;
			})[0];
			var cookieMenuItem = _this._buildMenuItem(curSettings, curTexts);
			cookieMenu.appendChild(cookieMenuItem);
		}
		document.querySelector('.cookie-settings').appendChild(cookieMenu);
	}

	_buildFooter () {
		var _this = this;
		// var cookieTexts = _this.options.texts.cookieTypes;
		var cookieFooter = document.createElement('div');
		cookieFooter.className = 'cookie-content-footer';
		var cookieSaveBtn = document.createElement('button');
		cookieSaveBtn.className = 'cookie-btn-save';
		cookieSaveBtn.textContent = _this.options.texts.buttons.save;
		cookieFooter.appendChild(cookieSaveBtn);
		document.querySelector('.cookie-content-container').appendChild(cookieFooter);
	}

	_buildSettingsWindow () {
		var settingsContainer = document.createElement('div');
		settingsContainer.setAttribute('class', 'cookie-settings');
		settingsContainer.classList.add('cookie-settings--' + this.options.theme);
		document.querySelector('body').appendChild(settingsContainer);
		this._buildMenu();
		this._buildContent();
		this._buildFooter();
	}

	_buildCookieTable (cookieName) {
		const tableContainer = document.createElement('div');
		tableContainer.classList.add('cookie-list');
		const cookieSettings = this.options.cookieSettings;
		if (typeof cookieSettings === 'undefined') {
			return tableContainer;
		}
		const thisCookieSettings = this.options.cookieSettings[cookieName];
		if (typeof thisCookieSettings === 'undefined' || typeof thisCookieSettings.list === 'undefined') {
			return tableContainer;
		}
		const cookieList = thisCookieSettings.list;

		if (cookieList.length === 0) {
			return tableContainer;
		}
		const tableMarkup = `
			<table class="cookie-table">
				<thead>
					<tr>
						<th>${ this.options.texts.cookieTable.thName }</th>
						<th>${ this.options.texts.cookieTable.thExpiry }</th>
					</tr>
				</thead>
				<tbody>
					${ cookieList.map(function (item) {
		return `
							<tr>
								<td>${ item.name }</td>
								<td>${ item.expiry }</td>
							</tr>
						`;
	}).join('') }
				</tbody>
			</table>
		`;

		tableContainer.innerHTML = tableMarkup;

		return tableContainer;
	}
}

export default CookieBar;
