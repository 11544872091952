const dataFeed = {
	pmi: {
		data: {
			cookieTypes: [ {
				id: 'provozni',
				voluntary: false,
				waitAccept: false,
				cookieName: 'cookieProvozni',
			},
			{
				id: 'funkcni',
				voluntary: true,
				waitAccept: true,
				checkedByDefault: true,
				cookieName: 'cookieFunkcni',
			},
			{
				id: 'personalizovane',
				voluntary: true,
				waitAccept: true,
				cookieName: 'cookiePersonalizovane',
			} ],
		},
	},
};

export default dataFeed;
