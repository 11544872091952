import utils from './helpers/utils.js';

const defaults = {
	theme: 'pmi-default',
	lang: 'cs',
	pathSrc: 'https://cookies.pmdata.cz/',
	additionalSelectors: [],
};

if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector ||
                              Element.prototype.webkitMatchesSelector;
}

class JsModal {
	constructor (options) {
		let thisDefaults = JSON.parse(JSON.stringify(defaults));

		if (options.src === 'pmi-ochrana-obchodniku') {
			thisDefaults.additionalSelectors.push('[href$="#otlink-dealer-notice"]');
		}
		if (options.src === 'pmi-ochrana-spotrebitele') {
			thisDefaults.additionalSelectors.push('[href$="#otlink-consumer-notice"]');
		}
		this.options = JSON.parse(JSON.stringify({
			...thisDefaults,
			...options,
		}));

		// console.log(this.options);

		this._buildDOM();
		this._regLinkListener(this.options);
	}

	open (id, startAt) {
		// var id = this.options.id;
		var modal = document.getElementById(id);

		if (modal === null) {
			console.log('No modal with id ' + id + ' found.');
			return;
		}

		var startAtEl = null;
		var modalScrollTop = 0;
		if (typeof startAt !== 'undefined' && startAt !== null && startAt !== '') {
			startAtEl = modal.querySelector(startAt);
		}

		var modalBackdrop = document.createElement('div');
		modalBackdrop.className = 'js-modal-backdrop';
		modalBackdrop.id = 'jsmodal-backdropClose-' + id;
		var modalToClose = document.querySelector('.js-modal.is-opened');
		if (modalToClose !== null) {
			modalToClose.classList.remove('is-opened');
		}

		modal.classList.add('is-opened');

		if (startAtEl !== null) {
			modalScrollTop = startAtEl.offsetTop;
		}

		modal.scrollTop = modalScrollTop;
		document.body.appendChild(modalBackdrop);

		// set modal backdrop event
		modalBackdrop.addEventListener('click', function (e) {
			var modalToClose = document.querySelector('.js-modal.is-opened');
			if (e.target.classList.contains('js-modal')) {
				e.stopImmediatePropagation();
			}
			if (modalToClose !== null) {
				modalToClose.classList.remove('is-opened');
				var backdrop = document.querySelector('.js-modal-backdrop');
				document.body.removeChild(backdrop);
			}
		});
	}

	close (id) {
		// var id = this.options.id;
		var modal = document.getElementById(id);
		var backdrop = document.querySelector('.js-modal-backdrop');
		modal.classList.remove('is-opened');
		document.body.removeChild(backdrop);
	}

	_buildDOM () {
		var opts = this.options;
		var modalWrap = document.createElement('div');
		modalWrap.className = 'js-modal';
		modalWrap.classList.add('js-modal--' + opts.theme);
		modalWrap.id = opts.id;

		var modalDialog = document.createElement('div');
		modalDialog.className = 'js-modal-dialog';
		modalWrap.appendChild(modalDialog);

		var modalContainer = document.createElement('div');
		modalContainer.className = 'js-modal-container';
		modalDialog.appendChild(modalContainer);

		var modalClose = document.createElement('button');
		modalClose.className = 'btn-close-js-modal';
		modalClose.id = 'jsmodal-btnCloseRightTop-' + opts.id;
		modalContainer.appendChild(modalClose);
		modalClose.addEventListener('click', function () {
			document.getElementById(opts.id).classList.remove('is-opened');
			var backdrop = document.querySelector('.js-modal-backdrop');
			document.body.removeChild(backdrop);
		});

		var modalCloseEnd = document.createElement('button');
		modalCloseEnd.className = 'btn-close-js-modal-end';
		modalCloseEnd.id = 'jsmodal-btnCloseBottomCenter-' + opts.id;
		modalCloseEnd.textContent = (opts.lang === 'cs' ? 'Zavřít' : 'Zatvoriť');
		modalContainer.appendChild(modalCloseEnd);

		modalCloseEnd.addEventListener('click', function () {
			document.getElementById(opts.id).classList.remove('is-opened');
			var backdrop = document.querySelector('.js-modal-backdrop');
			document.body.removeChild(backdrop);
		});


		utils.fetchContent(opts.pathSrc + 'contents/' + opts.src + '.' + opts.lang + '.html', function (content) {
			modalContainer.insertAdjacentHTML('afterbegin', content);
			document.body.appendChild(modalWrap);
		});
	}

	_regLinkListener (options) {
		var _this = this;
		options.additionalSelectors.forEach(selector => {
			// console.log(selector);
			document.querySelector('body').addEventListener('click', function (e) {
				// console.log(selector);
				if (e.target.matches(selector)) {
					e.preventDefault();
					// console.log(_this);
					_this.open(options.id, null);
				}
			}, true);
		});

		if (document.body.dataset.regLinkListening) {
			return;
		}
		else {
			document.body.dataset.regLinkListening = true;
		}

		document.querySelector('body').addEventListener('click', function (e) {
			var _this = this;
			// console.log(_this);
			if (e.target.classList.contains('js-modal-link')) {
				e.preventDefault();
				// e.stopImmediatePropagation();
				var href = e.target.getAttribute('data-href');
				var startAt = e.target.getAttribute('data-start-at');
				if (href === null || href === '#') {
					href = e.target.href;
				}
				if (href === null || href.substring(href.lastIndexOf('#') + 1) === '') {
					console.log('no link to modal found.');
					return;
				}

				if (startAt === null) {
					startAt = '';
				}
				href = href.substring(href.lastIndexOf('#') + 1);

				_this.open(href, startAt);
			}

		}.bind(this), true);
	}
}

export default JsModal;
