const lang = {
	pmi: {
		cs: {
			texts: {
				cookieNotice: {
					description: '<p>Soubory cookies (a podobné technologie) používáme k zajištění správného fungování těchto stránek, ke zlepšení sbírání dat týkajících se užívání těchto stránek a ke sledování vašeho zařízení na našich stránkách, abychom vám mohli poskytnout marketingovou nabídku ušitou na míru. Kliknutím na „Přijmout všechny“ souhlasíte s používáním cookies, pokud byste se chtěli dozvědět víc nebo určitá cookies nepovolit, klikněte na „Zjistit více“.</p><p>Kdykoli můžete nastavení změnit na odkazu „Nastavení cookies“ na jakékoli stránce. Všechny osobní údaje budou použity v souladu se <a href=\'#\' class=\'js-modal-link\' data-href=\'#jsZasadyModal\' data-gtm-push=\'{"event":"GAEvent","eventCategory":"Button","eventAction":"Click","eventLabel":"cookies-PMI-zasady"}\'>PMI Zásadami o ochraně soukromí spotřebitelů</a>.</p>',
				},
				cookieTypes: [ {
					id: 'provozni',
					menuLabel: 'PROVOZNÍ',
					heading: 'Provozní soubory cookies.',
					description: '<p>Zajištění fungování stránek a jejich funkcí. Například: správné zobrazení obsahu stránek na vašem zařízení. Dále zajišťují fungování stránek a jejich funkcí uživatelsky přátelským způsobem. Například: pamatují si, kdo jste, vaše preference a nastavení, takže je nemusíte zadávat pokaždé, když přejdete na jinou stránku, např. zvolení jazyka nebo nastavení regionu.</p><p>Tyto soubory cookies jsou nezbytné ke správnému fungování stránek nebo k poskytnutí požadovaných funkcí, proto tento nástroj neumožňuje soubory zrušit. Pokud si však budete přát, můžete ve vyhledavači nastavit odmítnutí souboru cookies. V tomto případě se stránka nemusí správně načíst a zvolené funkce nemusí být dostupné.</p>',
				},
				{
					id: 'funkcni',
					menuLabel: 'FUNKČNÍ',
					heading: 'Funkční soubory cookies.',
					description: '<p>Umožňují zlepšení fungování stránek. Například: porozumět tomu, jak lidé používají naše stránky nebo jak dobře fungují. Z těchto technologií získáváme pouze anonymní informace: získané informace jsou redukovány tak, že identifikují pouze místo, kde se nacházíte, a před tím, než je obdržíme, jsou agregovány.</p>',
				},
				{
					id: 'personalizovane',
					menuLabel: 'Personalizované a&nbsp;marketingové',
					heading: 'Personalizované a marketingové soubory cookies.',
					description: '<p>Sledují historii vyhledávání na stránce a jiných stránkách a slouží jako cílená komunikace s uživatelem. Například: jeden z našich poskytovatelů zaměstnává třetí stranu ke sledování a analýze používání a objemu statistických informací od jednotlivců, kteří navštíví digitální platformu.</p>',
				} ],
				buttons: {
					moreInfo: 'Zjistit více',
					accept: 'Přijmout',
					acceptAll: 'Přijmout všechny',
					save: 'ULOŽIT',
				},
				cookieTable: {
					thName: 'Název cookie',
					thExpiry: 'Expirace',
				},
			},
		},
		sk: {
			texts: {
				cookieNotice: {
					description: '<p>Na tejto stránke používame cookies (a iné podobné technológie) k jej prevádzke, zlepšovaniu pomocou zhromažďovania údajov o tom, ako túto stránku používate, k monitorovaniu správania vášho zariadenia na tejto stránke a iných stránkach, preto vám môžeme ponúknuť používanie, ktoré je viac personalizované z hľadiska marketingu. Kliknutím na „Akceptovať všetko“ súhlasíte s používaním týchto cookies, alebo pokiaľ sa chcete dozvedieť viac alebo deaktivovať určité cookies, kliknite prosím na „Zistiť viac“.</p><p>Svoje rozhodnutie môžete kedykoľvek zmeniť pomocou linku „Nastavenie súborov cookies“ na jednotlivých stránkach webu. Upozorňujeme na to, že všetky osobné údaje, ktoré sa zhromažďujú, budú používané tak, ako je opísané v našich <a href=\'#\' class=\'js-modal-link\' data-href=\'#jsZasadyModal\' data-gtm-push=\'{"event":"GAEvent","eventCategory":"Button","eventAction":"Click","eventLabel":"cookies-PMI-zasady"}\' >PMI Zásadách o ochrane súkromia spotrebiteľov</a>.</p>',
				},
				cookieTypes: [
					{
						id: 'provozni',
						menuLabel: 'Prevádzkové',
						heading: 'Prevádzkové cookies.',
						description: '<p>Zaisťujú prevádzku stránky a jej funkcií. Príklad: zobrazujú správne obsah na vašom zariadení. Takisto zaisťujú pohodlné fungovanie stránky a jej funkcií. Príklady: pamätajú si, kto ste, aké sú vaše preferencie a nastavenia, preto ich nepotrebujete zadávať zakaždým, keď stránku navštívite, napríklad výber preferovaného jazyka alebo regionálne nastavenie.</p><p>Tieto cookies sú dôležité pre prevádzkovanie stránky alebo pre poskytovanie funkcií, ktoré požadujete, preto túto funkciu nemôžete odhlásiť alebo zrušiť. Jednako pokiaľ si to prajete, môžete nastaviť svoj prehliadač tak, aby odmietal všetky cookies; v takom prípade sa však stránka nebude riadne zobrazovať a vami zvolené funkcie nebudú k dispozícii.</p>',
					},
					{
						id: 'funkcni',
						menuLabel: 'Funkčné',
						heading: 'Funkčné cookies.',
						description: '<p>Umožňujú nám zlepšiť spôsob, akým stránka funguje. Príklad: chápu, ako ľudia používajú našu stránku alebo ako dobre funguje. Od týchto technológií dostávame len anonymné informácie: informácie, ktoré zhromažďujú, sú obmedzené, preto sa zisťuje iba oblasť, kde sa nachádzate, a potom dochádza k ich agregovaniu ešte predtým, kým informácie získame.</p>',
					},
					{
						id: 'personalizovane',
						menuLabel: 'Personalizované a marketingové',
						heading: 'Personalizované a marketingové súbory cookies.',
						description: '<p>Sledujú históriu prezerania na stránke a iných stránkach a slúžia na cielenú komunikáciu s používateľom. Príklad: Jeden z našich poskytovateľov služieb používa tretiu stranu na sledovanie a analyzovanie používania a objemu štatistických informácií od osôb, ktoré navštívia digitálnu platformu.</p>',
					},
				],
				buttons: {
					moreInfo: 'Zistiť viac',
					accept: 'Akceptovať',
					acceptAll: 'Akceptovať všetko',
					save: 'ULOŽIŤ',
				},
				cookieTable: {
					thName: 'Názov cookie',
					thExpiry: 'Expirácie',
				},
			},
		},
	},
};

export default lang;
